import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// Assets
import BedIQueenIcon from "./../images/svg/bed-queen.svg";

// Components
import ListGroup from "./listGroup";
import ListGroupItem from "./listGroupItem";

// Styles
const StyledCard = styled.div`
  border: 4px solid var(--acapulco);

  .small {
    font-size: 15px;
  }

  .btn {
    width: 100%;
  }
`;

//Props
interface IListtemProps {
  icon: any;
  text: string;
}

interface IProps {
  title: string;
  list: IListtemProps[];
  url: string;
  airbnb: string;
}

const Card: FC<IProps> = ({ title, list, url, airbnb }) => {
  const { t } = useTranslation();

  const getIcon = (icon: string) => {
    let svg: any;
    switch (icon) {
      case "double-bed":
        svg = <BedIQueenIcon />;
        break;
      default:
        svg = <BedIQueenIcon />;
    }

    return svg;
  };

  return (
    <StyledCard className="d-flex flex-column align-items-center">
      <h3 className="mb-0 py-4 text-capitalize bg-acapulco w-100 text-center">
        {t(title)}
      </h3>
      <div className="px-3 py-5">
        <div className="mb-2">
          <ListGroup>
            {list.map(({ icon, text }, index) => (
              <ListGroupItem key={index} icon={getIcon(icon)} text={text} />
            ))}
          </ListGroup>
        </div>
        <p className="text-center small mb-2">Reservar por:</p>
        <div className="text-center d-flex justify-content-between flex-column">
          <a
            href={url}
            className="btn btn-airbnb rounded-pill px-4 py-2 me-3 mb-3"
            target="_blank"
          >
            <span className="text-white fw-bold pr-2">{t(airbnb)}</span>
          </a>
          <a
            href="https://www.booking.com/hotel/cr/vista-lago-arenal-piedras1.es.html?label=gen173nr-1FCAsoM0IadmlzdGEtbGFnby1hcmVuYWwtcGllZHJhczFIClgEaDOIAQGYAQq4ARjIAQzYAQHoAQH4AQOIAgGoAgS4Ava135sGwAIB0gIkNWY1Nzg2OGQtZDdjNS00YjI5LWE4ZTktNzQ2MGM4ZWZhMDhi2AIF4AIB&sid=40c05a98c0e7128a7bf9814b12719949&dist=0&keep_landing=1&sb_price_type=total&type=total&"
            className="btn btn-booking rounded-pill px-4 py-2 me-3"
            target="_blank"
          >
            <span className="text-white fw-bold pr-2">Booking</span>
          </a>
        </div>
      </div>
    </StyledCard>
  );
};

export default Card;
