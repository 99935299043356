import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import AppProvider from "./../components/appProvider";
import Layout from "./../components/layout";
import SEO from "./../components/seo";
import FadeInWhenVisible from "./../components/fadeInWhenVisible";
import Hero from "./../components/hero";
import Spacer from "./../components/spacer";
import Card from "./../components/card";
import Sphere from "./../components/sphere";
import Album from "./../components/album";
import TextWithIcon from "./../components/textWithIcon";
import Contact from "./../components/contact";

// Constants
import { unitsImagesAlts, commonImagesAlts } from "./../modules/constants";

// Assets
import CheckInIcon from "./../images/svg/clock.svg";
import CheckOutIcon from "./../images/svg/clock.svg";
import SmokeIcon from "./../images/svg/no-smoking.svg";
import PartyIcon from "./../images/svg/party.svg";
import WifiIcon from "./../images/svg/wifi.svg";
import WashingIcon from "./../images/svg/washing.svg";
import DryerIcon from "./../images/svg/dryer.svg";
import HotWaterIcon from "./../images/svg/hot-water.svg";
import TowelsIcon from "./../images/svg/towels.svg";
import ParkingIcon from "./../images/svg/parking.svg";
import FootprintIcon from "./../images/svg/footprint.svg";
import KitchenIcon from "./../images/svg/kitchen.svg";
import OvenIcon from "./../images/svg/oven.svg";
import SilverwareIcon from "./../images/svg/silverware.svg";
import RefrigeratorIcon from "./../images/svg/refrigerator.svg";
import StoveIcon from "./../images/svg/stove.svg";
import CoffeeMakerIcon from "./../images/svg/coffee-maker.svg";
import OilIcon from "./../images/svg/oil.svg";
import BarbecueUtensilsIcon from "./../images/svg/barbecue-utensils.svg";
import PillowIcon from "./../images/svg/pillow.svg";
import BedLinensIcon from "./../images/svg/bed-linens.svg";
import ShampooIcon from "./../images/svg/shampoo.svg";
import PatioIcon from "./../images/svg/patio.svg";
import GardenIcon from "./../images/svg/garden.svg";
import MapIcon from "./../images/svg/navigator.svg";

const IndexPage = ({ data, location }: any) => {
  const { t } = useTranslation();
  const { site, heroImage, units, common } = data || [];

  const translateServices: any = t("services", {
    returnObjects: true,
  });

  const translateRules: any = t("rules.list", {
    returnObjects: true,
  });

  const { siteUrl, phoneRef } = site.siteMetadata;

  const { basics, facilities, kitchen, betAndBath, outdoor } =
    translateServices || [{}];

  const getTranslation = (string: string): any =>
    t(string, { returnObjects: true });

  const unitsData = getTranslation("units");

  const servicesIcons = {
    basics: [
      <WifiIcon />,
      <WashingIcon />,
      <DryerIcon />,
      <TowelsIcon />,
      <HotWaterIcon />,
    ],
    facilities: [<ParkingIcon />, <FootprintIcon />],
    kitchen: [
      <KitchenIcon />,
      <OvenIcon />,
      <SilverwareIcon />,
      <RefrigeratorIcon />,
      <StoveIcon />,
      <CoffeeMakerIcon />,
      <OilIcon />,
      <BarbecueUtensilsIcon />,
    ],
    betAndBath: [<PillowIcon />, <BedLinensIcon />, <ShampooIcon />],
    outdoor: [<PatioIcon />, <GardenIcon />],
  };

  const rulesIcons = [
    <CheckInIcon />,
    <CheckOutIcon />,
    <SmokeIcon />,
    <PartyIcon />,
  ];

  const airbnbLinks = [
    {
      url: "https://www.airbnb.co.cr/rooms/547038520602833720?source_impression_id=p3_1654653090_vrBQiADFq1SJJSle",
    },
    {
      url: "https://www.airbnb.co.cr/rooms/558085397887318215?source_impression_id=p3_1654653092_LG9GBCv9HT0Wfjo2",
    },
    {
      url: "https://es-l.airbnb.com/rooms/662186647924755970?unique_share_id=93af2629-16c5-471d-b1bb-89960aca1db2",
    },
  ];

  const renderUnitsCards = (data: any) => {
    return data.map((data: any, index: number) => (
      <Col xs={10} sm={12} lg={6} xl={4} className="mb-4" key={index}>
        <Card
          title={data.heading}
          list={data.items}
          url={airbnbLinks[index].url}
          airbnb={unitsData.OtaAirbnb}
        />
      </Col>
    ));
  };

  return (
    <AppProvider location={location}>
      <Layout>
        <SEO />
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${siteUrl}",
              "name": "${process.env.GATSBY_WEBSITE_NAME}",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "${phoneRef}",
                "contactType": "Customer Support"
              }
            }
          `}
          </script>
        </Helmet>
        <section className="anchor-block" id="main">
          <Hero image={heroImage} title="hero.title" className="mb-3 mb-lg-4" />
        </section>
        <section className="anchor-block" id="units">
          <FadeInWhenVisible>
            <div className="bg-merino">
              <Spacer>
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={10} sm={12}>
                      <h2 className="mb-5">{unitsData.heading}</h2>
                      <p className="mb-0">{unitsData.description}</p>
                    </Col>
                  </Row>
                </Container>
              </Spacer>
              <Spacer bottomOnly={true}>
                <Container>
                  <Row className="justify-content-center justify-content-md-start">
                    {renderUnitsCards(unitsData.list)}
                  </Row>
                </Container>
              </Spacer>
              <Spacer bottomOnly={true}>
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={10} sm={6}>
                      <div className="mb-3">
                        <Sphere
                          id="sphereUnits1"
                          sceneId="sceneUnits1"
                          image="/tour/room1.jpg"
                          preview="/tour/room1-preview.jpg"
                          yaw={40}
                          pitch={6}
                        />
                      </div>
                    </Col>
                    <Col xs={10} sm={6}>
                      <div className="mb-3">
                        <Sphere
                          id="sphereUnits2"
                          sceneId="sceneUnits2"
                          image="/tour/bath.jpg"
                          preview="/tour/bath-preview.jpg"
                          yaw={96}
                          pitch={8}
                        />
                      </div>
                    </Col>
                    <Col xs={10} sm={6}>
                      <div className="mb-3">
                        <Sphere
                          id="sphereUnits3"
                          sceneId="sceneUnits3"
                          image="/tour/room2.jpg"
                          preview="/tour/room2-preview.jpg"
                          yaw={-46}
                          pitch={-40}
                        />
                      </div>
                    </Col>
                    <Col xs={10} sm={6}>
                      <Album
                        images={units}
                        alts={unitsImagesAlts}
                        border="acapulco"
                      />
                    </Col>
                  </Row>
                </Container>
              </Spacer>
            </div>
          </FadeInWhenVisible>
        </section>
        <section className="anchor-block" id="common-spaces">
          <FadeInWhenVisible>
            <div className="bg-white">
              <Spacer>
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={10} sm={12}>
                      <h2 className="mb-5">{t(`commonSpaces.heading`)}</h2>
                      <p className="mb-0">{t(`commonSpaces.descriptionOne`)}</p>
                    </Col>
                  </Row>
                </Container>
              </Spacer>
              <Spacer bottomOnly={true}>
                <Container>
                  <Row className="justify-content-center justify-content-sm-start">
                    <Col xs={10} sm={6} className="order-2 order-xl-1">
                      <div className="common">
                        <Album
                          images={common}
                          border="acapulco"
                          alts={commonImagesAlts}
                        />
                      </div>
                    </Col>
                    <Col xs={10} sm={6} className="order-1 order-xl-2">
                      <div className="mb-2">
                        <Sphere
                          id="sphereCommon1"
                          sceneId="sceneCommonOne"
                          image="/tour/common1.jpg"
                          preview="/tour/common1-preview.jpg"
                          yaw={136}
                          pitch={-2}
                        />
                      </div>
                      <div className="mb-2">
                        <Sphere
                          id="sphereCommonThree"
                          sceneId="sceneThree"
                          image="/tour/common2.jpg"
                          preview="/tour/common2-preview.jpg"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Spacer>
            </div>
          </FadeInWhenVisible>
        </section>
        <section className="anchor-block" id="services">
          <FadeInWhenVisible>
            <div className="bg-gin">
              <Container>
                <Row className="justify-content-center justify-content-sm-start">
                  <Col xs={10} sm={12}>
                    <Spacer>
                      <h2 className="m-0">{t(`services.heading`)}</h2>
                    </Spacer>
                  </Col>
                </Row>
              </Container>
              <Spacer bottomOnly={true}>
                <div className="Services">
                  <Container>
                    <Row className="justify-content-center justify-content-sm-start">
                      <Col xs={10} className="mb-4 mb-lg-5">
                        <h3 className="mb-0">{t(basics.heading)}</h3>
                      </Col>
                      {basics.list.map((item: any, index: number) => {
                        return (
                          <Col
                            xs={10}
                            md={6}
                            xl={4}
                            className="mb-4"
                            key={index}
                          >
                            <TextWithIcon
                              icon={servicesIcons.basics[index]}
                              heading={item.heading}
                              subheading={
                                item.subheading ? item.subheading : null
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <Row className="justify-content-center justify-content-sm-start">
                      <Col xs={10} className="my-4 my-lg-5">
                        <h3 className="mb-0">{t(facilities.heading)}</h3>
                      </Col>
                      {facilities.list.map((item: any, index: number) => {
                        return (
                          <Col
                            xs={10}
                            md={6}
                            xl={4}
                            className="mb-4"
                            key={index}
                          >
                            <TextWithIcon
                              icon={servicesIcons.facilities[index]}
                              heading={item.heading}
                              subheading={
                                item.subheading ? item.subheading : null
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <Row className="justify-content-center justify-content-sm-start">
                      <Col xs={10} className="my-4 my-lg-5">
                        <h3 className="mb-0">{t(kitchen.heading)}</h3>
                      </Col>
                      {kitchen.list.map((item: any, index: number) => {
                        return (
                          <Col
                            xs={10}
                            md={6}
                            xl={4}
                            className="mb-4"
                            key={index}
                          >
                            <TextWithIcon
                              icon={servicesIcons.kitchen[index]}
                              heading={item.heading}
                              subheading={
                                item.subheading ? item.subheading : null
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <Row className="justify-content-center justify-content-sm-start">
                      <Col xs={10} className="my-4 my-lg-5">
                        <h3 className="mb-0">{t(betAndBath.heading)}</h3>
                      </Col>
                      {betAndBath.list.map((item: any, index: number) => {
                        return (
                          <Col
                            xs={10}
                            md={6}
                            xl={4}
                            className="mb-4"
                            key={index}
                          >
                            <TextWithIcon
                              icon={servicesIcons.betAndBath[index]}
                              heading={item.heading}
                              subheading={
                                item.subheading ? item.subheading : null
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <Row className="justify-content-center justify-content-sm-start">
                      <Col xs={10} className="my-4 my-lg-5">
                        <h3 className="mb-0">{t(outdoor.heading)}</h3>
                      </Col>
                      {outdoor.list.map((item: any, index: number) => {
                        return (
                          <Col
                            xs={10}
                            md={6}
                            xl={4}
                            className="mb-4"
                            key={index}
                          >
                            <TextWithIcon
                              icon={servicesIcons.outdoor[index]}
                              heading={item.heading}
                              subheading={
                                item.subheading ? item.subheading : null
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
              </Spacer>
            </div>
          </FadeInWhenVisible>
        </section>
        <section className="anchor-block" id="drive">
          <FadeInWhenVisible>
            <Spacer>
              <div className="bg-white">
                <Container>
                  <Row className="align-items-center justify-content-center">
                    <Col xs={10} sm={12} md={5}>
                      <h2 className="mb-5">{t(`drive.heading`)}</h2>
                      <p className="mb-5">{t(`drive.description`)}</p>
                      <div className="mb-5">
                        <a
                          className="btn btn-dark rounded-pill py-2 px-4 text-capitalize font-weight-bold"
                          // This first one is from the cabinas
                          // href="https://www.google.com/maps/place/Vista+Lago+Arenal/@10.5605473,-84.9674967,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xa18ae1cb8859035e!8m2!3d10.5605473!4d-84.965308?hl=es-419"
                          // This second one is from the google maps list
                          href="https://www.google.com/maps/place/Cabinas+Vista+Lago+Arenal/@10.5610207,-84.9657641,18.77z/data=!4m8!3m7!1s0x0:0xa18ae1cb8859035e!5m2!4m1!1i2!8m2!3d10.5606132!4d-84.9653107?hl=es-419"
                          target="_blank"
                        >
                          {t(`drive.cta`)}
                        </a>
                      </div>
                    </Col>
                    <Col xs={10} md={7}>
                      <MapIcon />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Spacer>
          </FadeInWhenVisible>
        </section>
        <section className="anchor-block" id="contact">
          <FadeInWhenVisible>
            <div className="bg-merino">
              <Container>
                <Row className="justify-content-center justify-content-sm-start">
                  <Col xs={10} sm={12}>
                    <Spacer>
                      <h2 className="m-0">{t(`contact.heading`)}</h2>
                    </Spacer>
                    <Spacer bottomOnly={true}>
                      <Contact />
                    </Spacer>
                  </Col>
                </Row>
              </Container>
            </div>
          </FadeInWhenVisible>
        </section>
        <section className="anchor-block" id="rules">
          <FadeInWhenVisible>
            <div className="bg-white">
              <Container>
                <Row className="justify-content-center justify-content-sm-start">
                  <Col xs={10} sm={12}>
                    <Spacer>
                      <h2 className="m-0">{t(`rules.heading`)}</h2>
                    </Spacer>
                  </Col>
                </Row>
              </Container>
              <Spacer bottomOnly={true}>
                <div className="rules">
                  <Container>
                    <Row className="justify-content-center justify-content-sm-start">
                      {translateRules.map((item: any, index: number) => {
                        return (
                          <Col
                            xs={10}
                            md={6}
                            xl={4}
                            className="mb-4"
                            key={index}
                          >
                            <TextWithIcon
                              icon={rulesIcons[index]}
                              heading={item.heading}
                              subheading={
                                item.subheading ? item.subheading : null
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </div>
              </Spacer>
            </div>
          </FadeInWhenVisible>
        </section>
      </Layout>
    </AppProvider>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        phoneRef
      }
    }
    heroImage: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2200)
      }
    }
    units: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "units" }
      }
      sort: { order: ASC, fields: name }
    ) {
      totalCount
      edges {
        node {
          base
          name
          id
          childImageSharp {
            gatsbyImageData(width: 1100)
          }
        }
      }
    }
    common: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "common" }
      }
      sort: { order: ASC, fields: name }
    ) {
      totalCount
      edges {
        node {
          base
          name
          id
          childImageSharp {
            gatsbyImageData(width: 1100)
          }
        }
      }
    }
  }
`;

export default IndexPage;
