import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import BackgroundImage from "./backgroundImage";

// Styles
const StyledHero = styled.div`
  height: 50vh;
  margin: 82 auto 0;
  max-width: 2200px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    height: calc(100vh - 80px);
  }
`;

const Content = styled.div`
  bottom: 0;
  left: 0;
  padding: 1.5rem 0;
  position: absolute;
  text-align: left;
  width: 100vw;

  @media (min-width: 576px) {
    padding: 3rem 1rem;
  }

  h1 {
    @media (max-width: 767px) {
      font-size: 1.525rem !important;
    }
  }

  .btn {
    text-align: center;
    width: 160px;
  }
`;

// Types
interface IProps {
  image: any;
  title: string;
  className: any;
}

const Hero: FC<IProps> = ({ image, title }) => {
  const { t } = useTranslation();
  return (
    <StyledHero>
      <BackgroundImage image={image} />
      <Content>
        <Container fluid>
          <Row>
            <Col lg={8} xl={6}>
              <h1 className="mb-0 text-white">{t(`${title}`)}</h1>
              {/* <div className="d-none d-md-block">
                <h3 className="mb-3 text-white">Reservar por:</h3>
                <a
                  href="#"
                  className="btn btn-airbnb rounded-pill px-4 py-2 me-3"
                >
                  <span className="text-white fw-bold pr-2">
                    airbnb
                  </span>
                </a>
              </div> */}
            </Col>
          </Row>
        </Container>
      </Content>
    </StyledHero>
  );
};

export default Hero;
