import React, { FC } from "react";
import ReactPannellum from "react-pannellum";
import styled from "styled-components";

// Assets
import RotationIcon from "./../images/svg/rotation.svg";

const Rotation = styled((props: any) => <RotationIcon {...props} />)`
  background-color: var(--white);
  height: 56px;
  width: 56px;
  z-index: 100;
`;

const SphereWrapper = styled.div`
  .border-white {
    border: 5px solid var(--white) !important;
  }

  .border-acapulco {
    border: 5px solid var(--acapulco) !important;
  }

  .pnlm-load-button {
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color 0.25s linear;

    &:hover {
      background-color: rgba(255, 255, 255, 0.85);
    }
  }
`;

interface IProps {
  id: string;
  sceneId: string;
  image: any;
  preview?: any;
  bgColor?: string;
  yaw?: number;
  pitch?: number;
}

const Sphere: FC<IProps> = ({
  id,
  sceneId,
  image,
  preview,
  bgColor = "gin",
  yaw = 0,
  pitch = 0,
}) => {
  const config = {
    autoLoad: false,
    showZoomCtrl: false,
    keyboardZoom: false,
    mouseZoom: false,
    showFullscreenCtrl: false,
    yaw: yaw,
    pitch: pitch,
    preview: preview,
  };

  return (
    <SphereWrapper>
      <div className="d-flex flex-column align-items-center position-relative border-acapulco">
        <Rotation className="position-absolute top-0 end-0 p-1" />
        <ReactPannellum
          id={id}
          sceneId={sceneId}
          imageSource={image}
          config={config}
          style={{
            width: "100%",
            height: "400px",
            background: `var(--${bgColor})`,
          }}
        />
      </div>
    </SphereWrapper>
  );
};

export default Sphere;
