import React, { FC } from "react";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import styled from "styled-components";
import BgImg from "gatsby-background-image";

// Styles
const StyledBgImg = styled(BgImg)`
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  width: 100%;
  z-index: -1;

  @media (min-width: 992px) {
    height: calc(100vh - 80px);
  }
`;

const Mask = styled((props) => <div {...props} />)`
  background: ${({ mask }) => mask};
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
`;

// Types
interface IProps {
  image: any;
  bgColor?: string;
  mask?: string | null;
}

const BackgroundImage: FC<IProps> = ({
  image,
  bgColor = "bg-gun-powder",
  mask = "rgba(0, 0, 0, 0.25)",
}) => {
  const img = getImage(image);
  const bgImage = convertToBgImage(img);

  return (
    <>
      <StyledBgImg
        Tag="section"
        {...bgImage}
        backgroundColor={bgColor}
        preserveStackingContext
        className="position-absolute"
      />
      {mask && <Mask mask={mask} />}
    </>
  );
};

export default BackgroundImage;
